import React, { useState } from 'react'
import { Container, Form, Button, Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const HomePage = () => {
  const navigate = useNavigate()
  const [user, setUser] = useState('')

  const onSubmit = (e) => {
    e.preventDefault()
    navigate(`/${user}`)
  }
  return (
    <div className="container p-5 text-center bg-light text-secondary rounded shadow mt-5">
      <h2> Welcome To NrydeChat </h2>
      <p> Welcome To NrydeChat </p>
      <div className="d-flex justify-content-around">
        <div className="col-12 col-md-6">
          <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label className="fw-bold">Enter UserId </Form.Label>
              <Form.Control
                type="text"
                value={user}
                onChange={(e) => setUser(e.target.value)}
              />
            </Form.Group>
            <div className="text-center">
              <Button
                variant="success"
                type="submit"
                className="mt-2 px-5 fw-bold"
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default HomePage
