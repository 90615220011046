import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { StreamChat } from 'stream-chat'
import {
  Chat,
  Channel,
  ChannelHeader,
  ChannelList,
  MessageList,
  MessageInput,
  LoadingIndicator,
  Thread,
  Window,
} from 'stream-chat-react'
import '@stream-io/stream-chat-css/dist/css/index.css'

const apiKey = process.env.REACT_APP_STREAM_CHAT_KEY
const options = { state: true, presence: true, limit: 10 }
const sort = { last_message_at: -1 }

const IrydeChat = ({ user, clearUser, token, id }) => {
  const navigate = useNavigate()
  const [client, setClient] = useState(null)
  const [channel, setChannel] = useState(null)
  const filters = { type: ['team', 'messaging'], members: { $in: [user.id] } }

  useEffect(() => {
    async function init() {
      const chatClient = StreamChat.getInstance(apiKey)
      try {
        // await chatClient.disconnectUser()
        await chatClient.connectUser(user, token)
        // await chatClient.connectUser(user, chatClient.devToken(user.id))

        const channel = chatClient.channel('messaging', id, {
          name: 'admin',
          members: [{ user_id: id }],
        })
        // await channel.addMembers(['afa0267-5400-4a95-b6b8-987b5d310361'])
        // Add user to the channel with role set
        await channel.create()
        await channel.watch()
        setChannel(channel)
        setClient(chatClient)
      } catch (error) {
        console.log('error')
        console.log(error)
        navigate('/error_page')
      }
    }

    init()
    if (client) return () => client.disconnectUser()
  }, [])

  // const LogoutHandler = () => {
  //   client.disconnectUser()
  // clearUser()
  //   navigate('/')
  // }

  if (!client)
    return (
      <div className="d-flex justify-content-center">
        <span className="mr-2"> Loading </span>
        <LoadingIndicator />
      </div>
    )

  return (
    <div className="page-content">
      {/* <div className="text-end mb-2">
        <span className="fw-bold"> {user.name} </span>
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={LogoutHandler}
        >
          Logout
        </button>
      </div> */}
      <div>
        <Chat client={client} theme="messaging light">
          {/* <ChannelList filter={filters} sort={sort} showChannelSearch={true} /> */}
          <Channel channel={channel}>
            <Window>
              <ChannelHeader />
              <MessageList />
              <MessageInput focus />
            </Window>
            <Thread />
          </Channel>
        </Chat>
      </div>
    </div>
  )
}

export default IrydeChat
