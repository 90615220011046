import React, { useContext, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import ProtectedRoute from './route/protectedPage'

import Chat from './Pages/chat'
import AuthContext from './Components/Contexts/AuthContext'
import LoginPage from './Pages/Login'
import HomePage from './Pages/HomePage'
import NotFound from './Pages/NotFound'
import Error_Page from './Pages/Error_Page'

const App = () => {
  const { account, loadUserHandler } = useContext(AuthContext)

  useEffect(() => {
    loadUserHandler()
  }, [])
  return (
    <div>
      {account.isAuth !== null ? (
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <HomePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/:id"
              element={
                <ProtectedRoute>
                  <Chat />
                </ProtectedRoute>
              }
            />
            <Route
              path="/error_page"
              element={
                <ProtectedRoute>
                  <Error_Page />
                </ProtectedRoute>
              }
            />
            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      ) : (
        <div className="vh-100">Loading....</div>
      )}
    </div>
  )
}

export default App
