import React, { useEffect, useState, useContext } from 'react'
import AuthContext from '../../Components/Contexts/AuthContext'
import { useParams } from 'react-router-dom'

import { Container } from 'react-bootstrap'
import Chat from './chat'

const Index = () => {
  let { id } = useParams()
  const [userInfo, setUserInfo] = useState(null)
  const {
    account: { user, token },
  } = useContext(AuthContext)
  useEffect(() => {
    let userInfo = {
      image:
        'https://nryde-assets.s3.eu-west-1.amazonaws.com/meleage/f7887040-b4f3-11ed-9b27-df8f35d7103b/logo.png',
      name: user?.firstName + ' ' + user?.lastName,
      id: user?.id,
    }

    setUserInfo(userInfo)
  }, [])

  console.log(user)

  return (
    <div className="bg-light vh-100">
      {userInfo && id ? (
        <Chat user={user} token={token} id={id} />
      ) : (
        <p className="text-center"> Atleast a user is required</p>
      )}
    </div>
  )
}

export default Index
